<template>
  <v-card class="mt-n10 moholand-shadow">
    <v-card-title>
      ایجاد سرفصل جدید
      <v-spacer></v-spacer>

      <v-btn color="primary" :to="destinationRoute">
        <v-icon>mdi-undo-variant</v-icon>
        بازگشت
      </v-btn>
    </v-card-title>
    <v-card-text>
      <v-form ref="form" @submit.prevent="submit">
        <v-form-base
          :col="{ cols: 12, md: 6 }"
          :model="model"
          :schema="schema"
        />
        <multi-input
          class="mt-4"
          v-model="model.gallery"
          :schema="gallerySchema"
          title="ویدئو های این سرفصل"
        />
        <v-row align="center" justify="end" class="mt-8">
          <v-btn @click="clear" depressed>
            پاک کردن فرم
          </v-btn>

          <v-btn
            class="mx-4"
            dark
            color="success"
            :loading="loading"
            type="submit"
          >
            ایجاد سرفصل جدید
          </v-btn>
        </v-row>
      </v-form>
    </v-card-text>
  </v-card>
</template>

<script>
import { Constants } from "@/constants/GlobalConstants";
import MyAxios from "@/constants/MyAxios";
import VFormBase from "vuetify-form-base";
import { formPostPrepare } from "@/constants/PanelFormHandler";
import MultiInput from "@/components/MultiInput.vue";
export default {
  components: {
    VFormBase,
    MultiInput,
  },
  created() {
    this.getCourses();
    // this.getSellers();
  },
  data() {
    return {
      rules: Constants.rules,
      destinationRoute:
        this.$route.fullPath.slice(
          0,
          this.$route.fullPath.indexOf("create") - 1
        ) +
        "/index" +
        (this.$route.query?.page ? "?page=" + this.$route.query?.page : ""),
      loading: false,
      model: {
        title: "",
        course_id: +this.$route.params.course_id || "",
        desc: "",
        gallery: [],
        is_locked: false,
        order: "",
      },
      schema: {
        title: {
          type: "CustomInput",
          label: "نام سرفصل",
          rules: [Constants.rules.required],
          required: true,
          col: { cols: 12, md: 6 },
        },
        course_id: {
          type: "CustomInput",
          inputType: "select",
          items: [],
          itemValue: "id",
          itemText: "title",
          label: "دوره",
          rules: [Constants.rules.required],
          loading: true,
          required: true,
          col: { cols: 12, md: 6 },
        },
        order: {
          type: "CustomInput",
          label: "ترتیب",
          required: true,
          col: { cols: 12, md: 6 },
        },
        is_locked: {
          type: "checkbox",
          label: "قفل",
          class: "pa-2 align-center",
          required: true,
          col: { cols: 12, md: 6 },
        },
        desc: {
          type: "MyTinyMCE",
          label: "توضیحات",
          rules: [Constants.rules.required],
          required: true,
          col: { cols: 12 },
        },
      },
      gallerySchema: {
        // title: {
        //   type: "CustomInput",
        //   label: "نام ویدئو",
        //   rules: [Constants.rules.required],
        //   required: true,
        // },
        media_id: {
          type: "MyFilePond",
          title: "ویدئو",
          fileTypes: "video/mp4",
          maxFileSize: "300MB",
          rules: [Constants.rules.required],
          required: true,
          col: { cols: 12 },
          isRemote: true
        },
        link: {
          type: "CustomInput",
          label: "لینک فایل",
          class: "ltr",
          col: { cols: 12 },
        },
      },
    };
  },

  methods: {
    getCourses() {
      if (!this.schema.course_id.loading) this.schema.course_id.loading = true;

      MyAxios.get("/courses/index", {
        params: {
          conditions: {
            status: 1,
          },
        },
      })
        .then((response) => {
          this.schema.course_id.loading = false;
          this.schema.course_id.items = response.data.data;
        })
        .catch((error) => {
          this.schema.course_id.loading = false;
        });
    },
    submit() {
      if (this.$refs.form.validate()) {
        let files = this.model.gallery.filter(
          (val) => val?.media_id || val?.link?.length
        );

        this.loading = true;

        let data = formPostPrepare(this.model);
        data.append("slug", this.model.title.replace(/\/|\s|\./g, "-"));
        data.append("is_locked", +this.model.is_locked);
        if (this.model.order) data.append("order", +this.model.order);

        if (files.length > 0)
          data.append(
            "files",
            JSON.stringify(
              files.map((val) => {
                let is_external =
                  typeof val.link == "string" && val?.link?.length;
                return {
                  is_external: !!is_external || undefined,
                  media_id: is_external
                    ? undefined
                    : val.media_id?.id || val.media_id,
                  link: is_external ? val.link : undefined,
                };
              })
            )
          );

        MyAxios.post("chapters/create", data)
          .then((response) => {
            this.loading = false;
            this.$root.$emit("toast", {
              text: "سرفصل با موفقیت ایجاد شد.",
              type: "success",
            });
            console.log(response);
            this.$router.replace(this.destinationRoute);
          })
          .catch((error) => {
            this.loading = false;
            this.$root.$emit("toast", {
              text: "مشکلی پیش آمده است. لطفا دوباره سعی کنید",
            });
            console.log(error);
          });
      } else {
        this.$root.$emit("toast", {
          text: "لطفا اطلاعات را به درستی وارد نمایید.",
        });
      }
    },
    clear() {
      this.$refs.form.reset();
      this.$refs.form.resetValidation();
    },
  },
};
</script>

<style></style>
